import '../assets/css/form.css';

import { LoadingOutlined } from "@ant-design/icons";
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from "yup";


import { _change_password, _update_password } from '../common/axios_services';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const   ChangePassword = () => {
    let navigate = useNavigate();
    const [sendingMessage, setSendingMessage] = useState(false);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const routerParams = useParams();

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    const updatePasswordVerification = yup.object().shape({
        new_password: yup.string().required('Please enter your new password').min(6, 'New password can not be less than 6 characters'),
        confirm_new_password: yup.string().required('Please re-enter your new password').min(6, 'New password can not be less than 6 characters')
    })

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(updatePasswordVerification)
    });


    const updateUserPassword = async e => {
        if (e.new_password !== e.confirm_new_password) {
            openNotificationWithIcon('error', "Your passwords have to match");
        } else {
            setSendingMessage(true);
            let passwordData = {
                id: routerParams.id,
                new_password: e.new_password,
                confirm_new_password: e.confirm_new_password,
                token: routerParams.token,
                type: routerParams.token ? "token" : "pass_code"
            }
            try {
                let updatePass = await _update_password(passwordData);
                setSendingMessage(false);
                openNotificationWithIcon('success', 'Password reset successfully. Please sign in to continue');
                navigate('/signin');
            } catch (err) {
                setSendingMessage(false);
                openNotificationWithIcon('error', err.response.data.message);
            }
        }
    }

    return (
        <div className="form-pages">
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages">
                    <div className="">
                        <div className="form auth">
                            <div className="form-text">
                                <h3>Change password!</h3>
                                <p>Please enter your new password. Get richer each day with Wise9ja. Enjoy free delivery and cashback when you shop.</p>
                            </div>
                            <form onSubmit={handleSubmit(updateUserPassword)}>
                                <div className="form-group">
                                    <label htmlFor="new_password" style={{ width: '100%' }}>New Password</label>
                                    <Controller control={control} defaultValue="" name="new_password"
                                        render={({ field }) => (
                                            <Input.Password {...field} id="new_password" style={{ width: '100%', height: '5rem' }}
                                                type="password" />
                                        )
                                        } />
                                    {errors.new_password && <p className="error-message">{errors.new_password.message}</p>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="confirm_new_password" style={{ width: '100%' }}>Confirm Password</label>
                                    <Controller control={control} defaultValue="" name="confirm_new_password"
                                        render={({ field }) => (
                                            <Input.Password {...field} id="confirm_new_password" style={{ width: '100%', height: '5rem' }}
                                                type="password" />
                                        )
                                        } />
                                    {errors.confirm_new_password && <p className="error-message">{errors.confirm_new_password.message}</p>}
                                </div>
                                <div style={{ marginTop: '5%' }}></div>
                                {!sendingMessage ? (
                                    <button id="submit-form" className="bg_green" style={{ width: '100%', height: '5rem', borderRadius: '3px' }} type="submit">Update Password</button>
                                ) : (
                                    <button className="bg_green" disabled loading style={{ width: '100%', height: '5rem', borderRadius: '3px' }}><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                )}
                                {/* <button id="submit-form" className="bg_green" disabled={true} style={{ width: '100%', height: '5rem', borderRadius: '3px' }} type="submit">Resetting Password... Please wait</button> */}
                            </form>
                            <div style={{ marginBottom: '20px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg_grey">
                    <div className="container form_design_pages">
                        <div className="div_bg_white">
                            <div className="form aut">
                                <div className="form-text">
                                    <h3 className="noSpace">Change password!</h3>
                                    <p>Please enter your new password. Get richer each day with Wise9ja. Enjoy free delivery and cashback when you shop.</p>
                                </div>
                                <form onSubmit={handleSubmit(updateUserPassword)}>
                                    <div className="form-group">
                                        <label htmlFor="new_password" style={{ width: '100%' }}>New Password</label>
                                        <Controller control={control} defaultValue="" name="new_password"
                                            render={({ field }) => (
                                                <Input.Password {...field} id="new_password" style={{ width: '100%', height: '5rem' }}
                                                    type="password" />
                                            )
                                            } />
                                        {errors.new_password && <p className="error-message">{errors.new_password.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirm_new_password" style={{ width: '100%' }}>Confirm Password</label>
                                        <Controller control={control} defaultValue="" name="confirm_new_password"
                                            render={({ field }) => (
                                                <Input.Password {...field} id="confirm_new_password" style={{ width: '100%', height: '5rem' }}
                                                    type="password" />
                                            )
                                            } />
                                        {errors.confirm_new_password && <p className="error-message">{errors.confirm_new_password.message}</p>}
                                    </div>
                                    <div style={{ marginTop: '5%' }}></div>
                                    {!sendingMessage ? (
                                        <button id="submit-form" className="bg_green" style={{ width: '100%', height: '5rem', borderRadius: '3px' }} type="submit">Update Password</button>
                                    ) : (
                                        <button className="bg_green" disabled loading style={{ width: '100%', height: '5rem', borderRadius: '3px' }}><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                    )}
                                    {/* <button id="submit-form" className="bg_green" disabled={true} style={{ width: '100%', height: '5rem', borderRadius: '3px' }} type="submit">Resetting Password... Please wait</button> */}
                                </form>
                                <div style={{ marginTop: '4%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ChangePassword;