import React, { useState } from 'react'
import { useEffect } from 'react'
import axiosCall from '../common/axios_call';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom'

const SubHeader = () => {
    const [subcategories, setSubcategories] = useState([]);
    useEffect(() => {
        axiosCall('/sub-category/no_pagination')
            .then(res => {
                setSubcategories(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    let skeleton = [];
    for (let i = 0; i < 21; i++) {
        skeleton.push(<Skeleton.Button active size={'default'}
            shape={'default'}
        />)
    }
    return (
        <div className="desktop sec-nav-group px-4">
            <div className="links">
                {
                    subcategories.length ?
                        subcategories.map((subcategory, index) => (
                            <div className="item" key={index}>
                                <Link to={`/subcategories/${subcategory.id}`}>{subcategory.subcat_name}</Link>
                            </div>
                        ))
                        :
                        skeleton.map((placeHolder, index) => (
                            <div className="item" key={index}>
                                {placeHolder}
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default SubHeader