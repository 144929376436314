import { Divider } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { _get_profile } from '../../common/axios_services';
import { fetchData, profileData } from '../../slices/profileSlice';


const Profile = () => {
    const userData = useSelector(state => state.userProfile.dataFetched)
    const profileInfo = useSelector(state => state.userProfile.dataFetched);
    const dispatch = useDispatch()

    const logout = () => {
        localStorage.removeItem('konnect_token')
        window.location.reload()
    }

    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true))
            dispatch(profileData(user_profile?.data?.data))
        } catch (err) { }
    }

    useEffect(() => {
        fetchUser();
    }, [])
    
    return (
        <div style={{ height: '100%' }}>
            <ul>
                <h4 style={{ padding: 0 }} className="profile_nav_title">
                    <ion-icon class="profile_icon" name="storefront-outline"></ion-icon> My Dashboard
                </h4>
            </ul>
            <Divider style={{ margin: '10px 0px' }} />
            <ul>
                {profileInfo?.tier !== "tier_2" &&
                    <li>
                        <NavLink to="/profile/complete-kyc" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })} >
                            <ion-icon class="profile_icon" name="document-text-outline"></ion-icon> Complete KYC
                        </NavLink>
                    </li>
                }
                {profileInfo?.tier === "tier_2" &&
                    <li>
                        <NavLink to="/profile/loyalty" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })} >
                            <ion-icon class="profile_icon" name="wallet-outline"></ion-icon> Account
                        </NavLink>
                    </li>
                }
                {profileInfo?.tier === "tier_2" &&
                    <li>
                        <NavLink to="/profile/referral" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })}>
                            <ion-icon class="profile_icon" name="people-outline"></ion-icon> Referral
                        </NavLink>
                    </li>
                }
                {userData &&
                    <>
                        {(userData.is_sl || userData.is_sa) && userData.is_ra &&
                            <li>
                                <NavLink to="/profile/sl" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })}>
                                    <ion-icon class="profile_icon" name="people-outline"></ion-icon> Sales
                                </NavLink>
                            </li>
                        }
                    </>
                }
                <li>
                    <NavLink to="/profile/order_history" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })} >
                        <ion-icon class="profile_icon" name="card-outline"></ion-icon> Order
                    </NavLink>
                </li>
                {profileInfo?.tier === "tier_2" &&
                    <li>
                        <NavLink to="/profile/bills" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })} >
                            <ion-icon class="profile_icon" name="wallet-outline"></ion-icon> Bills
                        </NavLink>
                    </li>
                }
                <li>
                    <NavLink to="/profile/wishlist" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })} >
                        <ion-icon class="profile_icon" name="pricetag-outline"></ion-icon> Wishlist
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/profile/overview" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })}>
                        <ion-icon class="profile_icon" name="settings-outline"></ion-icon> Profile
                    </NavLink>
                </li>
            </ul>
            <div>
                <Divider style={{ margin: '10px 0px' }} />
                <ul>
                    <li>
                        <NavLink to="/contact" style={({ isActive }) => ({ color: !isActive ? '#111111' : '#258635', })} >
                            <ion-icon class="profile_icon" name="person-outline"></ion-icon> Support
                        </NavLink>
                    </li>
                    <li className="auth-signout">
                        <button onClick={logout} style={{ border: "none", color: "red", fontSize: "14px", background: "transparent", outline: "none" }}>
                            <ion-icon class="profile_icon" name="log-out-outline"></ion-icon> Sign Out
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Profile;