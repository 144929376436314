/* eslint-disable */

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Form, Input, Modal, Pagination, Result, Select, Spin, Switch, Table, Tabs, Tag, notification } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Slider from "react-slick";

import { Link, useNavigate } from 'react-router-dom';
import Jwt_decrypt from '../../common/Jwt_decrypt';
import axiosCall from '../../common/axios_call';
import { _get_all_invoice, _get_all_orders } from '../../common/axios_services';
import Navbar from '../../components/Navbar';
import Profile from '../../components/dashboard/side_nav';
import Footer from '../../components/Footer';


const OrderHistory = () => {
    const navigate = useNavigate()
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const params = new URLSearchParams(window?.location?.search);
    const [tx_ref,] = useState(params.get('tx_ref') ? params.get('tx_ref') : '');
    const [status,] = useState(params.get('status') ? params.get('status') : '');
    const [transaction_id,] = useState(params.get('transaction_id') ? params.get('transaction_id') : '');

    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const profileData = useSelector(state => state.userProfile.dataFetched)
    const [acc_bal, setAcc_bal] = useState(0)
    const [order_his, setOrder_his] = useState([]);
    const [deliveredOrder, setDeliveredOrder] = useState([]);
    const [notDeliveredOrder, setNotDeliveredOrder] = useState([]);
    const [currency, setCurrency] = useState("NGN")
    const [wallet_bal, setWallet_bal] = useState(0)
    const [voucher_bal, setVoucher_bal] = useState(0)
    const [wise9ja_bal, setWise9ja_bal] = useState(0)
    const [loadingData, setLoadingData] = useState(true);
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const [tableDisplay, setTableDisplay] = useState('all');

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };
    const jwt = localStorage.konnect_token

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/signin')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_token })

    const allTransOrders = async () => {
        try {
            const allOrders = await _get_all_orders({ page, page_size: per_page })
            if (allOrders.data.status === "success") {
                let delivered = [];
                let not_delivered = [];
                allOrders.data.data.forEach(order => {
                    if (order.delivery_status === "pending") {
                        not_delivered.push(order);
                    } else {
                        delivered.push(order);
                    }
                    setDeliveredOrder(delivered);
                    setNotDeliveredOrder(not_delivered);
                });
                setOrder_his(allOrders.data.data);
                setTotal_trans(allOrders.data.meta.total);
                setLoadingData(false);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading data')
                setLoadingData(false);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate("/signin?redir=review")
                    localStorage.removeItem('konnect_token')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
            setLoadingData(false);
        }
    }

    useEffect(() => {
        allTransOrders();
        axiosCall(`account/details`, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
            setCurrency(res.data.data.currency)
            setAcc_bal(res.data.data.wallet_bal + res.data.data.voucher_bal)
            setWallet_bal(res.data.data.wallet_bal)
            setVoucher_bal(res.data.data.voucher_bal)
            setWise9ja_bal(res.data.data.wisenija_bal)
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }, [per_page, page, tx_ref, status, transaction_id])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPer_page(pageSize);
    }

    const order_col = [
        {
            title: 'S/N',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Reference',
            dataIndex: 'ref',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'quantity',
            align: 'center',
        },
        {
            title: 'Weight (kg)',
            className: 'column-number',
            dataIndex: 'weight',
            align: 'center',
        },
        {
            title: 'Amount Paid (NGN)',
            className: 'column-number',
            dataIndex: 'amount',
            align: 'center',
        },
        {
            title: 'Total Amount (NGN)',
            className: 'column-number',
            dataIndex: 'total_amount',
            align: 'center',
        },
        {
            title: 'Delivery Option',
            className: 'column-number',
            dataIndex: 'deliveryopt',
            align: 'center',
        },
        {
            title: 'Payment Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'order_status',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ]

    const shortenText = ({ text, maxLength }) => {
        return text.length <= maxLength ? text : text.slice(0, maxLength / 2 - 1) + "..." + text.slice(text.length - maxLength / 2 + 2);
    }

    let delivered_order_history = deliveredOrder.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            date: order.created_at.split('T')[0],
            ref: shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length }),
            quantity: order.total_qty,
            weight: order.total_weight,
            amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount_paid) * 1)} />,
            deliveryopt: <p style={{ textTransform: "capitalize" }}>{order?.delivery_opt}</p>,
            total_amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount) * 1)} />,
            status: <div style={{ textTransform: "capitalize" }}>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</div>,
            order_status: <div style={{ textTransform: "capitalize" }}>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" || order.status === "canceled" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</div>,
            action: <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View</Button></Link>,
        }
    });
    let not_delivered_order_history = notDeliveredOrder.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            date: order.created_at.split('T')[0],
            ref: shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length }),
            quantity: order.total_qty,
            weight: order.total_weight,
            amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount_paid) * 1)} />,
            deliveryopt: <p style={{ textTransform: "capitalize" }}>{order?.delivery_opt}</p>,
            total_amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount) * 1)} />,
            status: <div style={{ textTransform: "capitalize" }}>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</div>,
            order_status: <div style={{ textTransform: "capitalize" }}>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" || order.status === "canceled" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</div>,
            action: <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View</Button></Link>,
        }
    });
    let order_history_data = order_his.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            date: order.created_at.split('T')[0],
            ref: shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length }),
            quantity: order.total_qty,
            weight: order.total_weight,
            amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount_paid) * 1)} />,
            deliveryopt: <p style={{ textTransform: "capitalize" }}>{order?.delivery_opt}</p>,
            total_amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount) * 1)} />,
            status: <div style={{ textTransform: "capitalize" }}>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</div>,
            order_status: <div style={{ textTransform: "capitalize" }}>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" || order.status === "canceled" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</div>,
            action: <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View</Button></Link>,
        }
    });

    return (
        <>
            <div className="userprofile">
                <div className="profile-content">
                    <Spin spinning={spinnerLoading} indicator={newAntIcon}>
                        <Navbar />
                        <div className="gray_bg_profile">
                            <div className="profile_to_left">
                                <div className="">
                                    <div className="profile_nav">
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                            <div className="profile_to_right">
                                <div className="">
                                    <div className="contain">
                                        <div className="main_container">
                                            <div className="main_container_title_div">
                                                <h5>Order History</h5>
                                                <Divider style={{ margin: '0px 0 5px' }} />
                                            </div>
                                            {/* Tables */}
                                            <div>
                                                <div className="mt-2">
                                                    {loadingData ?
                                                        <div>
                                                            <Spin indicator={newAntIcon} />
                                                        </div> :
                                                        <>
                                                            <div className="tag-box">
                                                                <button className={`tag ${tableDisplay === "all" ? 'active-tag' : ''}`} onClick={() => setTableDisplay('all')}>
                                                                    <p>All Orders</p></button>
                                                                <button className={`tag ${tableDisplay === "delivered" ? 'active-tag' : ''}`}
                                                                    onClick={() => setTableDisplay('delivered')}><p>Delivered</p></button>
                                                                <button className={`tag ${tableDisplay === "not_delivered" ? 'active-tag' : ''}`}
                                                                    onClick={() => setTableDisplay('not_delivered')}><p>To Deliver</p></button>
                                                            </div>
                                                            {
                                                                tableDisplay === "all" ?
                                                                    <div>
                                                                        <div className="desktop show-all">
                                                                            <Table scroll={{ x: true }} pagination={false} bordered columns={order_col} dataSource={order_history_data} />
                                                                            <div style={{ marginTop: "2%" }}></div>
                                                                            <Pagination showSizeChanger current={page} total={total_trans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`} defaultPageSize={per_page} onChange={change_page} />
                                                                        </div>
                                                                        <div className="mobile topup-data">
                                                                            <Collapse defaultActiveKey={[0]}>
                                                                                {
                                                                                    order_his.map((order, index) => (
                                                                                        <Collapse.Panel showArrow={false} key={index}
                                                                                            header={<p style={{ display: 'inline' }}><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order.total_amount) * 1)} /></span> ordered on {order.created_at.split('T')[0]}</p>}>
                                                                                            <div>
                                                                                                <ul className="mobile-list-flex">
                                                                                                    <li>
                                                                                                        <span>Date:</span><span>{order.created_at.split('T')[0]}</span>
                                                                                                    </li>
                                                                                                    <li><span>Reference:</span><span>{shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length })}</span></li>
                                                                                                    <li><span>Quantity:</span><span>{order.total_qty}</span></li>
                                                                                                    <li><span>Weight <span className='currency'>(kg)</span>:</span><span>{order.total_weight}</span></li>
                                                                                                    <li><span>Amount paid <span className='currency'>(NGN)</span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount_paid) * 1)} /></span></li>
                                                                                                    <li><span>Total Amount <span className='currency'>(NGN)</span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount) * 1)} /></span></li>
                                                                                                    <li><span>Delivery option:</span><span style={{ textTransform: "capitalize" }}>{order?.delivery_opt}</span></li>
                                                                                                    <li><span>Payment Status:</span><span>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</span></li>
                                                                                                    <li><span>Status:</span><span>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" || order.status === "canceled" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</span></li>
                                                                                                </ul>
                                                                                                <div className="mt-3">
                                                                                                    <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View Order</Button></Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Collapse.Panel>
                                                                                    ))}
                                                                            </Collapse>
                                                                            <div style={{ marginTop: "2%" }}></div>
                                                                            <Pagination showSizeChanger current={page} total={total_trans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`} defaultPageSize={per_page} onChange={change_page} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    tableDisplay === "delivered" ?
                                                                        <div>
                                                                            <div className="desktop show-all">
                                                                                <Table scroll={{ x: true }} pagination={false} bordered columns={order_col} dataSource={delivered_order_history} />
                                                                                <div style={{ marginTop: "2%" }}></div>
                                                                            </div>
                                                                            <div className="mobile topup-data">
                                                                                <Collapse defaultActiveKey={[0]}>
                                                                                    {
                                                                                        deliveredOrder.map((order, index) => (
                                                                                            <Collapse.Panel showArrow={false} key={index}
                                                                                                header={<p style={{ display: 'inline' }}><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order.total_amount) * 1)} /></span> ordered on {order.created_at.split('T')[0]}</p>}>
                                                                                                <div>
                                                                                                    <ul className="mobile-list-flex">
                                                                                                        <li>
                                                                                                            <span>Date:</span><span>{order.created_at.split('T')[0]}</span>
                                                                                                        </li>
                                                                                                        <li><span>Reference:</span><span>{shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length })}</span></li>
                                                                                                        <li><span>Quantity:</span><span>{order.total_qty}</span></li>
                                                                                                        <li><span>Weight <span className='currency'>(kg)</span>:</span><span>{order.total_weight}</span></li>
                                                                                                        <li><span>Amount paid <span className='currency'>(NGN)</span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount_paid) * 1)} /></span></li>
                                                                                                        <li><span>Total Amount <span className='currency'>(NGN)</span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount) * 1)} /></span></li>
                                                                                                        <li><span>Delivery option:</span><span style={{ textTransform: "capitalize" }}>{order?.delivery_opt}</span></li>
                                                                                                        <li><span>Payment Status:</span><span>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</span></li>
                                                                                                        <li><span>Status:</span><span>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" || order.status === "canceled" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</span></li>
                                                                                                    </ul>
                                                                                                    <div className="mt-3">
                                                                                                        <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View Order</Button></Link>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Collapse.Panel>
                                                                                        ))}
                                                                                </Collapse>
                                                                                <div style={{ marginTop: "2%" }}></div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div className="desktop show-all">
                                                                                <Table scroll={{ x: true }} pagination={false} bordered columns={order_col} dataSource={not_delivered_order_history} />
                                                                                <div style={{ marginTop: "2%" }}></div>
                                                                            </div>
                                                                            <div className="mobile topup-data">
                                                                                <Collapse defaultActiveKey={[0]}>
                                                                                    {
                                                                                        notDeliveredOrder.map((order, index) => (
                                                                                            <Collapse.Panel showArrow={false} key={index}
                                                                                                header={<p style={{ display: 'inline' }}><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order.total_amount) * 1)} /></span> ordered on {order.created_at.split('T')[0]}</p>}>
                                                                                                <div>
                                                                                                    <ul className="mobile-list-flex">
                                                                                                        <li>
                                                                                                            <span>Date:</span><span>{order.created_at.split('T')[0]}</span>
                                                                                                        </li>
                                                                                                        <li><span>Reference:</span><span>{shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length })}</span></li>
                                                                                                        <li><span>Quantity:</span><span>{order.total_qty}</span></li>
                                                                                                        <li><span>Weight <span className='currency'>(kg)</span>:</span><span>{order.total_weight}</span></li>
                                                                                                        <li><span>Amount paid <span className='currency'>(NGN)</span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount_paid) * 1)} /></span></li>
                                                                                                        <li><span>Total Amount <span className='currency'>(NGN)</span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.transaction?.total_amount) * 1)} /></span></li>
                                                                                                        <li><span>Delivery option:</span><span style={{ textTransform: "capitalize" }}>{order?.delivery_opt}</span></li>
                                                                                                        <li><span>Payment Status:</span><span>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</span></li>
                                                                                                        <li><span>Status:</span><span>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" || order.status === "canceled" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</span></li>
                                                                                                    </ul>
                                                                                                    <div className="mt-3">
                                                                                                        <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View Order</Button></Link>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Collapse.Panel>
                                                                                        ))}
                                                                                </Collapse>
                                                                                <div style={{ marginTop: "2%" }}></div>
                                                                            </div>
                                                                        </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <div className="profile mobile">
                <Footer />
            </div>
        </>
    )
}

export default OrderHistory;
