import { Button, Collapse, Divider, Form, Input, Modal, notification, Pagination, Spin, Table, Tabs, Tag, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ProfileNav from '../components/dashboard/side_nav';

import { InfoCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReferralIllustration from '../assets/images/icons/5_bell.png';
import Wallet from '../assets/images/icons/cil_wallet.png';
import GiftCard from '../assets/images/icons/gift_card.png';
import TablerCash from '../assets/images/icons/tabler_cash.png';
import Wise9ja from '../assets/images/icons/wise9ja.png';
import { _get_all_referrals, _get_bonus_history, _get_profile, _get_referral_pool, _recruit_so, _user_dashboard_referrals } from '../common/axios_services';
import InfoModal from '../components/InfoModal';
import { fetchData, profileData } from '../slices/profileSlice';

const Referral = () => {

    const jwt = localStorage.konnect_token
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.userProfile.dataFetched);

    const [stillLoading, setStillLoading] = useState(true);
    const [total_users, setTotal_users] = useState(0);
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [saModal, setSaModal] = useState(false);
    const [allReferrals, setAllReferrals] = useState([]);
    const [bonusHistory, setBonusHistory] = useState([]);
    const [bonusFetched, setBonusFetched] = useState(false);
    const [loadingSummary, setLoadingSummary] = useState(true);
    const [referralSummary, setReferralSummary] = useState({});
    const [referralModal, setReferralModal] = useState(false)
    const [userIsSa, setUserIsSa] = useState(false)
    const [userIsRa, setUserIsRa] = useState(false)
    const [referralMessageModal, setReferralMessageModal] = useState(false);
    const [recruitId, setRecruitId] = useState(false);
    const [recruitModal, setRecruitModal] = useState(false);
    const [recruitErr, setRecruitErr] = useState(false);
    const [recruitingSo, setRecruitingSo] = useState(false);
    const [soStatus, setSoStatus] = useState("all");
    const [referralPool, setReferralPool] = useState(false);
    const [referralPoolChange, setReferralPoolChange] = useState(userProfile.is_sa ? 'sa' : "");
    const [loadingReferralPool, setLoadingReferralPool] = useState(true);
    const [mainUrl] = useState(window.location.origin);
    const [user_ref_code] = useState(userProfile.ref_code);
    const [form] = Form.useForm();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', desc: '' });

    const openModal = (title, desc) => {
        setModalContent({ title, desc });
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };


    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: (userProfile?.is_sa && referralPool && referralPoolChange === "sa") ? 4 : 3,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    };

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Date Verified',
            dataIndex: 'dateVerified',
        },
        {
            title: 'Name of Referee',
            dataIndex: 'refereeName',
        },
        {
            title: 'Sign-up Bonus Earned',
            className: 'column-number',
            dataIndex: 'signUpBonus'
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status'
        },
    ];

    const SaColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Date Verified',
            dataIndex: 'dateVerified',
        },
        {
            title: 'Name of Referee',
            dataIndex: 'refereeName',
        },
        {
            title: 'Sign-up Bonus Earned',
            className: 'column-number',
            dataIndex: 'signUpBonus'
        },
        {
            title: 'Recruit',
            className: 'column-number',
            dataIndex: 'recruit'
        },
    ];

    const cashColumn = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Transaction date',
            dataIndex: 'date',
        },
        {
            title: 'Referee name',
            dataIndex: 'service',
        },
        {
            title: 'Transaction',
            className: 'column-number',
            dataIndex: 'credit',
        },
        {
            title: ' Referral income',
            className: 'column-number',
            dataIndex: 'debit',
        },
    ];

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            placement: 'bottom-right',
            description: message,
        });
    };

    const copyRefMessage = () => {
        const mainUrl = window.location.origin;
        const tempTextarea = document.createElement("textarea");

        const linkElement = document.createElement('div');
        linkElement.innerHTML = `Hi. Buy foodstuffs, grocery on Konnect & pay in bits. Sign-up & earn cash bonus. Click <a href="${mainUrl}/signup?konnectrfc=${user_ref_code}">${mainUrl}/signup?konnectrfc=${user_ref_code}</a>`;
        const linkText = linkElement.innerText;

        const plainText = `${linkText}`;

        tempTextarea.value = plainText;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        tempTextarea.setSelectionRange(0, 99999);

        document.execCommand("copy");
        setReferralMessageModal(false);

        document.body.removeChild(tempTextarea);
        openNotificationWithIcon('success', "Referral link copied successfully")
    };

    const getAllReferrals = async () => {
        setAllUsersFetched(true);
        try {
            const referrals = await _get_all_referrals({ page, page_size, category: soStatus });
            if (referrals.data.status === "success") {
                setAllUsersFetched(false);
                if (referrals?.data?.data) {
                    setAllReferrals(referrals?.data?.data);
                }
                setTotal_users(referrals?.data?.meta?.total);
            } else {
                setAllUsersFetched(false);
                openNotificationWithIcon('error', 'An error occurred while loading referral history');
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            setAllUsersFetched(false);
            openNotificationWithIcon('error', 'An error occurred while loading referral history');
        }
    }

    const getReferralSummary = async () => {
        setLoadingSummary(true);
        try {
            const referrals = await _user_dashboard_referrals();
            if (referrals.data.status === "success") {
                setLoadingSummary(false);
                setReferralSummary(referrals.data.data);
            } else {
                openNotificationWithIcon('error', referrals.data.message);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setAllReferrals([]);
        setBonusHistory([])
    }

    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            setStillLoading(false)
            dispatch(profileData(profile.data.data))
            setUserIsSa(profile.data.data.is_sa)
            setUserIsRa(profile.data.data.is_ra)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/signin')
                    localStorage.removeItem('konnect_token')
                    setStillLoading(false)
                    dispatch(profileData(false))
                    dispatch(fetchData(false))
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const getReferralPool = async () => {
        setLoadingReferralPool(true)
        try {
            const referral_pool = await _get_referral_pool();
            setReferralPool(referral_pool.data.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/signin')
                    localStorage.removeItem('konnect_token')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        } finally {
            setLoadingReferralPool(false)
        }
    }

    const getBounusHistory = async () => {
        try {
            const referrals = await _get_bonus_history({ page, page_size });
            if (referrals.data.status === "success") {
                setBonusHistory(referrals?.data?.data);
                setBonusFetched(true);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading referral history');
                setBonusHistory([]);
                setBonusFetched(false);
            }
        } catch (err) {
            setBonusFetched(false);
            setBonusHistory([]);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            openNotificationWithIcon('error', 'An error occurred while loading referral history');
        }
    }

    useEffect(() => {
        getReferralSummary();
        getReferralPool()
    }, [])

    useEffect(() => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/signin')
        }

        getProfile()
        getAllReferrals()
        getBounusHistory()
    }, [page, page_size, soStatus])

    let bonus_history = bonusHistory.map((trans, index) => {
        return {
            key: index,
            sn: index + 1,
            date: trans?.timestamp?.split('T')[0],
            service: trans.service,
            credit: trans.payment_type === "credit" ? (<div> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </div>) : "-",
            debit: trans.payment_type === "debit" ? (<div> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </div>) : "-",
            balance: <div><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.new_voucher_bal)).toFixed(2)} /></div>,
        }
    });

    const data = [];
    allReferrals.forEach((user, index) => {
        data.push({
            key: index,
            sn: index + 1,
            dateVerified: user?.created_at?.split('T')[0],
            refereeName: user?.user_name,
            status: <div style={{ textTransform: "capitalize" }}>{user?.user?.is_so ? <Tag color="green">SO</Tag> : <Tag color="orange">Non SO</Tag>}</div>,
            signUpBonus: user?.signup_bonus,
            recruit: user?.referee_type == "ra" ? <Button type='link' onClick={() => { RecruitModal(); setRecruitId(user?.id) }}>Recruit</Button> : <Button type='link' disabled>Recruit</Button>
        })
    });

    const RecruitModal = () => {
        setRecruitModal(true)
    }

    const FinishRecruit = async (values) => {
        setRecruitingSo(true)
        try {
            const recruit_so = await _recruit_so({ address: values.address, recruitId });
            setRecruitingSo(false)
            setRecruitId(false)
            setRecruitModal(false)
            openNotificationWithIcon('success', recruit_so?.data?.title, recruit_so?.data?.message);
        } catch (err) {
            setRecruitingSo(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon('success', err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                } else {
                    setRecruitErr(err?.response?.data?.message);
                }
            } else {
                setRecruitErr(false)
                openNotificationWithIcon('error', "Something went wrong", 'An error occurred while verifying OTP. Please try again');
            }
        }
    }

    return (
        <div className="userprofile">
            <Spin spinning={loadingSummary}>
                <div className="profile-content">
                    <Navbar />
                    <div className="gray_bg_profile">
                        <div className="profile_to_left">
                            <div className="">
                                <div className="profile_nav">
                                    <ProfileNav />
                                </div>
                            </div>
                        </div>
                        <div className="profile_to_right">
                            <div className="desktop show-all">
                                <div className="contain">
                                    <div className="main_container">
                                        <div className="main_container_title_div">
                                            <h5>My Referral Code - {user_ref_code}</h5>
                                            <Divider style={{ margin: '0px 0 5px' }} />
                                        </div>
                                        <div className="main_container_inner_div pb-2">
                                            <div className="profile_container">
                                                <div className="slider_div_to_top referral-data">
                                                    <Slider {...settings}>
                                                        <div key={1} className="item grid-4-box colored color_1" onClick={() => openModal('Verified referral', 'Numbers of verified users you referred to the platform.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={Wallet} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>Verified referrals</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2>{loadingSummary ? '-' : referralSummary.signup_referal}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={2} className="item grid-4-box colored color_2" onClick={() => openModal('Bonus earned', 'Cash earned for referring verified users.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>Bonus earned</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2><span className="currency">NGN</span>{loadingSummary ? '0.00' : referralSummary.bonus_balance > 0 ? <NumberFormat thousandSeparator={true}
                                                                            displayType={'text'} value={+(referralSummary.bonus_balance).toFixed(2)} /> : '0.00'}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(userProfile?.is_sa && referralPool && referralPoolChange === "sa") &&
                                                            <div key={3} className="item grid-4-box colored color_3" onClick={() => setSaModal(true)}>
                                                                <div className="slick-div">
                                                                    <div className="slick-box">
                                                                        <div className="icon-box">
                                                                            <img src={Wise9ja} alt="tabler cash" />
                                                                        </div>
                                                                        <div className="slider-main-content">
                                                                            <p>Sales Referral Pool</p>
                                                                            <h2>{loadingSummary ? '-' : <b>NGN {referralPool?.sa_referal_pool > 0 ? <NumberFormat thousandSeparator={true} displayType={'text'} value={+referralPool?.sa_referal_pool} /> : '0.00'}</b>}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div key={4} className="item grid-4-box colored color_5" onClick={() => openModal('Referral income', 'Cash earned from your referee successful transactions.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={GiftCard} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>RA Income</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2><span className="currency">NGN</span>{loadingSummary ? '0.00' : referralSummary.ra_income_bonus > 0 ? <NumberFormat thousandSeparator={true}
                                                                            displayType={'text'} value={+(referralSummary.ra_income_bonus).toFixed(2)} /> : '0.00'}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile px-3 referal-info">
                                <div>
                                    <h5 className="profile_mobile_title">My Code &mdash; {user_ref_code}</h5>
                                    <div className="slider_div_to_top">
                                        <Slider {...settings}>
                                            <div key={5} className="item grid-4-box colored color_1" onClick={() => openModal('Verified referral', 'Numbers of verified users you referred to the platform.')}>
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={Wallet} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <p>Verified referrals</p>
                                                                <Tooltip title='Click the box for more info'>
                                                                    <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                </Tooltip>
                                                            </div>
                                                            <h2>{loadingSummary ? '-' : referralSummary.signup_referal}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={6} className="item grid-4-box colored color_2" onClick={() => openModal('Bonus earned', 'Cash earned for referring verified users.')}>
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={TablerCash} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <p>Bonus earned</p>
                                                                <Tooltip title='Click the box for more info'>
                                                                    <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                </Tooltip>
                                                            </div>
                                                            <h2><span className="currency">NGN</span>{loadingSummary ? '0.00' : referralSummary.bonus_balance > 0 ? <NumberFormat thousandSeparator={true}
                                                                displayType={'text'} value={+(referralSummary.bonus_balance).toFixed(2)} /> : '0.00'}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {(userProfile?.is_sa && referralPool && referralPoolChange === "sa") &&
                                                <div key={7} className="item grid-4-box colored color_3" onClick={() => setSaModal(true)}>
                                                    <div className="slick-div">
                                                        <div className="slick-box">
                                                            <div className="icon-box">
                                                                <img src={Wise9ja} alt="tabler cash" />
                                                            </div>
                                                            <div className="slider-main-content">
                                                                <p>Sales Referral Pool</p>
                                                                <h2>{loadingSummary ? '-' : <b>NGN {referralPool?.sa_referal_pool > 0 ? <NumberFormat thousandSeparator={true} displayType={'text'} value={+referralPool?.sa_referal_pool} /> : '0.00'}</b>}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div key={8} className="item grid-4-box colored color_5" onClick={() => openModal('Referral income', 'Cash earned from your referee successful transactions.')}>
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={GiftCard} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <p>RA Income</p>
                                                                <Tooltip title='Click the box for more info'>
                                                                    <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                </Tooltip>
                                                            </div>
                                                            <h2><span className="currency">NGN</span>{loadingSummary ? '0.00' : referralSummary.ra_income_bonus > 0 ? <NumberFormat thousandSeparator={true}
                                                                displayType={'text'} value={+(referralSummary.ra_income_bonus).toFixed(2)} /> : '0.00'}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className="desktop">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 10px" }}>
                                    {/* {(userProfile?.is_sa) && (
                                        <div style={{ paddingLeft: "20px" }}>
                                            <details>
                                                <summary><span style={{ fontSize: "1.5rem" }}>Referral Pool</span></summary>
                                                <span style={{ fontSize: '1.3rem' }}>Cash you get when you refer. Its earned from your sales.</span>
                                            </details>
                                            <hr />
                                            <div style={{ display: "flex", gap: "20px", alignItems: "center", width: "fit-content" }}>
                                                {userProfile?.is_sa && <p style={{ width: "100%", wordBreak: "keep-all" }}>Sales agent</p>}
                                                <>
                                                    {(userProfile?.is_sa && referralPool && referralPoolChange === "sa") &&
                                                        <p style={{ width: "20rem" }}><b>NGN {referralPool?.sa_referal_pool > 0 ? <NumberFormat thousandSeparator={true} displayType={'text'} value={+referralPool?.sa_referal_pool} /> : '0.00'}</b></p>
                                                    }
                                                </>
                                            </div>
                                        </div>)
                                    } */}
                                    <div></div>
                                    <button onClick={() => setReferralModal(true)} className="bg_side_green">How Referral Works</button>
                                </div>
                            </div>
                            <div className="mobile">
                                <div style={{ padding: "10px 10px" }}>
                                    <button style={{ width: '100%' }} onClick={() => setReferralMessageModal(true)}
                                        className="bg_side_green">Copy Referral Message</button>
                                </div>
                            </div>
                            <div className="mobile mt-3">
                                <div className="container">
                                </div>
                            </div>
                            <div className="contain mt-3">
                                <Tabs type='card'>
                                    <Tabs.TabPane tab="Verified Referrals History" key="1">
                                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div></div>
                                            <div style={{ marginBottom: "10px", width: "12rem" }}>
                                                <p>Filter:</p>
                                                <Select style={{ width: '100%', height: '5rem' }} value={soStatus} onChange={handleChangeFilter}>
                                                    <Select.Option value="all">All</Select.Option>
                                                    <Select.Option value="so">SO</Select.Option>
                                                    <Select.Option value="non_so">Non-SO</Select.Option>
                                                </Select>
                                            </div>
                                        </div> */}
                                        <div className="px-4 table-responsive">
                                            {!allUsersFetched ?
                                                <>
                                                    <div className="desktop show-all">
                                                        <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                    </div>
                                                    <div className="mobile topup-data">
                                                        <Collapse defaultActiveKey={[0]}>
                                                            {
                                                                allReferrals.map((trans, index) => (
                                                                    <Collapse.Panel showArrow={false} key={index}
                                                                        header={<p style={{ display: 'inline' }}>{trans.user_name} on {DateTime.fromISO(trans?.timestamp?.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}</p>}>
                                                                        <div>
                                                                            <ul className="mobile-list-flex">
                                                                                <li><span>Date Verified:</span><span>{trans?.created_at?.split('T')[0]}</span></li>
                                                                                <li><span>Name of Referee:</span><span>{trans.user_name}</span></li>
                                                                                <li><span>Sign-up Bonus Earned:</span><span>NGN <NumberFormat thousandSeparator={true} displayType={'text'} value={+(trans?.signup_bonus).toFixed(2)} /></span></li>
                                                                                <li><span>Status:</span><span style={{ textTransform: "capitalize" }}>{trans?.user?.is_so ? <Tag color="green">SO</Tag> : <Tag color="orange">Non SO</Tag>}</span></li>
                                                                            </ul>
                                                                        </div>
                                                                    </Collapse.Panel>
                                                                ))}
                                                        </Collapse>
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                    </div>
                                                </>
                                                :
                                                <div className="">
                                                    <Spin />
                                                </div>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Referral Income History" key="2">
                                        <div className="px-4 table-responsive">
                                            {bonusFetched ?
                                                <>
                                                    <div className="desktop show-all">
                                                        <Table columns={cashColumn} pagination={false} dataSource={bonus_history} bordered />
                                                    </div>

                                                    <div className="mobile topup-data">
                                                        <Collapse defaultActiveKey={[0]}>
                                                            {
                                                                bonusHistory.map((trans, index) => (
                                                                    <Collapse.Panel showArrow={false} key={index}
                                                                        header={<p style={{ display: 'inline' }}>{trans.payment_type === "credit" ? (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>)
                                                                            : (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>)}{trans.service} on {DateTime.fromISO(trans?.timestamp?.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}</p>}>
                                                                        <div>
                                                                            <ul className="mobile-list-flex">
                                                                                <li>
                                                                                    <span>Service:</span><span>{trans.service}</span>
                                                                                </li>
                                                                                <li><span>Date:</span><span>{trans?.timestamp?.split('T')[0]}</span></li>
                                                                                <li><span>Credit:</span>{trans.payment_type === "credit" ? (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>) : <span>&mdash;</span>}</li>
                                                                                <li><span>Debit:</span>{trans.payment_type === "debit" ? (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>) : <span>&mdash;</span>}</li>
                                                                                <li><span>Balance:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.new_voucher_bal)).toFixed(2)} /></span></li>
                                                                            </ul>
                                                                        </div>
                                                                    </Collapse.Panel>
                                                                ))}
                                                        </Collapse>
                                                        <div style={{ marginTop: "2%" }}></div>
                                                    </div>
                                                </>
                                                :
                                                <div className="">
                                                    <Spin />
                                                </div>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile mobile">
                    <Footer />
                </div>
            </Spin >
            <Modal centered={true} open={recruitModal} footer={null}>
                <div className="ref-modal">
                    <h3 className="header_summary" style={{ marginBottom: "20px" }}>Recruit An Sales Outlet</h3>
                    {recruitErr &&
                        <p style={{ color: "red" }}>{recruitErr}</p>
                    }
                    <Form layout="vertical" form={form} initialValues={{ address: "" }} onFinish={FinishRecruit} >
                        <Form.Item name="address" label="SO Address">
                            <Input placeholder="SO Address" />
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {recruitingSo ?
                                <Button loading disabled>Submitting...</Button> :
                                <Button type="primary" htmlType="submit">Submit</Button>
                            }
                            <Button onClick={() => { setRecruitModal(false); setRecruitId(false); form.resetFields(); setRecruitErr(false) }}>Close</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal open={referralModal} footer={null}>
                <div className="center-text ref-modal">
                    {/* <img src={BellIcon} alt="" />1 */}
                    <img src={ReferralIllustration} style={{ width: '20%', height: 'auto' }} alt="" />
                    <h3 className="header_summary">How Referral Works</h3>
                    <p className="how_it_works">You earn referral bonus when your friends sign up using your referral code. Your bonus will allow
                        you to enjoy discount when you shop and extra cash at Wise9ja. RA users earn cash income paid into
                        their wallet from shop and Wise9ja.
                    </p>
                    <button
                        style={{ borderRadius: '4px', marginTop: 30 }}
                        onClick={() => setReferralModal(false)} className="bg_blank">Close</button>
                </div>
            </Modal>
            <Modal open={referralMessageModal} footer={null} className="referal-modal" onCancel={() => setReferralMessageModal(false)}>
                <div className="center-text ref-modal">
                    <img src={ReferralIllustration} style={{ width: '20%', height: 'auto' }} alt="" />
                    <h3 className="header_summary">Copy Referral Message</h3>
                    <textarea rows="4" style={{ width: '100%', padding: 10, borderRadius: 4 }} disabled
                        value={`Hi. Buy foodstuffs, grocery on Konnect & pay in bits. Sign-up & earn cash bonus. Click <a href="${mainUrl}/signup?konnectrfc=${user_ref_code}">${mainUrl}/signup?konnectrfc=${user_ref_code}</a>`}>
                        <span></span>
                    </textarea>
                    {/* <Input value={`fdffsd`} name="referralCode" disabled
                        style={{ height: '4.5rem' }} /> */}
                    <button
                        style={{ borderRadius: '4px', marginTop: 10, fontSize: '1.4rem' }}
                        onClick={() => copyRefMessage()} className="bg_blank">Copy Message</button>
                </div>
            </Modal >
            <Modal centered open={saModal} onCancel={() => setSaModal(false)} footer={null} >
                <div>
                    <h1 className="modal-title">Referral Pool</h1>
                    <p>Sales Referral Pool is the cash you get when you refer <b>Food Vendors</b>.</p>
                    <Button type='primary' onClick={() => setSaModal(false)} style={{ marginTop: "10px" }}>Close</Button>
                </div>
            </Modal>

            <InfoModal
                title={modalContent.title}
                desc={modalContent.desc}
                visible={modalVisible}
                onClose={closeModal}
            />
        </div >
    )
}

export default Referral