import '../assets/css/shop.css';

import React, { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Empty, notification, Select, Skeleton, Spin } from 'antd';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { _all_product, _delivery_info, _general_settings_by_id, _shop_discount, _single_product } from '../common/axios_services';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Recommended from '../components/Recommended';
import { addToCart } from '../slices/cartSlice';

const Cart = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : '',
            description: message ? message : "",
            placement: "bottom-right"
        });
    };

    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [orderTotals, setOrderTotals] = useState(false);
    const [checkoutDatas, setCheckoutDatas] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [verifyingCartData, setVerifyingCartData] = useState(true);
    const [trend, setTrend] = useState([]);
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userProfile.dataFetched);
    const updateCounts = useSelector(state => state.updateCount.count);

    const options = [];
    for (let index = 1; index <= 50; index++) {
        options.push(index);
    };
    const { Option } = Select;

    let cart_info = useSelector(state => state.userCart.cart)

    const reduWeight = (total, num) => {
        return total + num.total_weight;
    }
    const reduPrice = (total, num) => {
        return total + num.total_price;
    }
    const reduCashback = (total, num) => {
        return total + num.cashback;
    }
    const DeliveryFees = async () => {
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        if (local_cart) {
            const data = {
                sub_amount: `${local_cart.data.reduce(reduPrice, 0)}`,
                discounts: `${0}`,
                cashback: `${local_cart.data.reduce(reduCashback, 0)}`,
                coupon: "0",
                app: 'web',
            }
            try {
                const delivery_info = await _delivery_info(data)
                const checkout_data = await _general_settings_by_id({ id: "1" })
                setCheckoutDatas(checkout_data?.data?.data)
                setOrderTotals(delivery_info.data)
            } catch (err) {
                setCheckoutDatas(false)
                setOrderTotals(false)
                if (err.response) {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err.message);
                }
            }
        }
    }
    const getProduct = async () => {
        try {
            const all_products = await _all_product({ page: '1', per_page: '14', search: '' })
            setTrend(all_products.data.data);
        } catch (err) {
            console.log(err.message);
        }
    }
    const checkProductPrice = async () => {
        const storedCart = JSON.parse(localStorage.getItem('konnect_cart'))
        if (storedCart && storedCart.data && storedCart.data.length > 0) {
            setSpinnerLoading(true)
            try {
                const updatedCart = await Promise.all(
                    storedCart.data.map(async product => {
                        try {
                            const checkPrice = await _single_product({ id: product?.product_id })
                            setSpinnerLoading(false)
                            let data = {
                                id: product?.id,
                                product_id: product?.product_id,
                                quantity: product?.quantity ? product?.quantity : 1,
                                timestamp: product?.timestamp,
                                product_name: product?.product_name,
                                product_image: product?.product_image,
                                description: checkPrice?.data?.data?.description,
                                product_price: checkPrice?.data?.data?.konnect_price,
                                supplier_price: checkPrice?.data?.data?.sellers_price,
                                weight: checkPrice?.data?.data?.weight,
                                discount_rate: checkPrice?.data?.data?.discount_rate,
                                discount: checkPrice?.data?.data?.konnect_price * checkPrice?.data?.data?.discount_rate * 0.01 * product?.quantity,
                                total_weight: product?.quantity * checkPrice?.data?.data?.weight,
                                total_price: product?.quantity * checkPrice?.data?.data?.konnect_price,
                                total_supplier_price: product?.quantity * checkPrice?.data?.data?.sellers_price,
                                cashback: 0,
                            }
                            return data;
                        } catch (err) {
                            setSpinnerLoading(false)
                            return null;
                        }
                    })
                )
                storedCart.data = updatedCart.filter(product => product !== null)
                localStorage.setItem('konnect_cart', JSON.stringify(storedCart));
                setVerifyingCartData(false)
                dispatch(addToCart(storedCart))
                setSpinnerLoading(false)
            } catch (err) {
                setSpinnerLoading(false)
                setVerifyingCartData(false)
                console.log('Error updating product prices:', err.message)
            }
        }
    }

    useEffect(() => {
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        dispatch(addToCart(local_cart))
        DeliveryFees()
        checkProductPrice()
    }, [updateCount, updateCounts]);

    useEffect(() => {
        getProduct()
    }, [])


    const updateCartQuantity = (value, id) => {
        setSpinnerLoading(true);
        const get_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        let all_data = get_cart
        const add_qty = get_cart.data.map(obj => {
            if (obj.product_id == id) {
                return {
                    id: obj.id,
                    product_id: obj.product_id,
                    quantity: value,
                    timestamp: obj.timestamp,
                    product_name: obj.product_name,
                    product_image: obj.product_image,
                    product_price: obj.product_price,
                    description: obj.description,
                    weight: obj.weight,
                    discount_rate: obj.discount_rate,
                    discount: obj.product_price * obj.discount_rate * 0.01 * value,
                    total_weight: value * obj.weight,
                    total_price: value * obj.product_price,
                    supplier_price: obj.supplier_price,
                    total_supplier_price: value * obj.supplier_price,
                    cashback: value * obj.cashback * obj.product_price * 0.01,
                };
            }
            return obj;
        })
        all_data.data = add_qty
        dispatch(addToCart(all_data))
        localStorage.setItem('konnect_cart', JSON.stringify(all_data))
        setOrderTotals(false)
        setUpdateCount(updateCount + 1)
        setSpinnerLoading(false);
    }

    const removeProductFromCart = (id) => {
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        const indexOfObject = local_cart.data.findIndex((object) => {
            return object.product_id === id;
        });

        if (indexOfObject !== -1) {
            local_cart.data.splice(indexOfObject, 1);
        }

        setOrderTotals(false)
        setUpdateCount(updateCount + 1)
        localStorage.setItem('konnect_cart', JSON.stringify(local_cart))
        dispatch(addToCart(local_cart))
    }

    const minOrderAlert = () => {
        if (userData && userData.is_sa) {
            openNotificationWithIcon("error", "Something went wrong", `Min order of NGN ${Number(checkoutDatas?.app_value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} before checkout`)
        } else {
            openNotificationWithIcon("error", "Something went wrong", `Min order of NGN ${Number(checkoutDatas?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} before checkout`)
        }
    }


    return (
        <div>
            <Spin indicator={antIcon} spinning={spinnerLoading}>
                <Navbar />
                <div className="cart">
                    <div>
                        <div className="contain">
                            <div className="marginTop"></div>
                            <div className="grid-flex">
                                <div className="combine">
                                    <h4>Your Cart</h4>
                                </div>
                            </div>
                            <div className="cart-details">
                                <div>
                                    {!cart_info ?
                                        <div className="empty-cart">
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                    <span>
                                                        You have no products in cart yet? <br />
                                                        <Link to="/shop">Go to shop</Link>
                                                    </span>
                                                }
                                            />
                                        </div>
                                        :
                                        cart_info.data ?
                                            <div className="cart-design-body">
                                                <div className="cart-design-tab new_cart">
                                                    {cart_info.data.map((product, index) => (
                                                        <div key={index}>
                                                            <div className={`${cart_info.data.length - index === 1 ? 'no-border' : ''} cart-body-detail`}>
                                                                <div className="inner-flex new_cart">
                                                                    <div className="cart_product_image">
                                                                        <img src={product.product_image} alt="product" />
                                                                    </div>
                                                                    <div className="cart_product_text">
                                                                        <div className="inner-flex new_cart">
                                                                            <div>
                                                                                <h4>{product.product_name}</h4>
                                                                            </div>
                                                                        </div>
                                                                        <p className="cart_product_description">
                                                                            <span className="small_currency">NGN</span>
                                                                            <NumberFormat value={product.product_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} /> each
                                                                        </p>
                                                                        <div className="mobile">
                                                                            <div className="mobile-flex-cover">
                                                                                <div className="mobile-flex">
                                                                                    <Select defaultValue={product.quantity}
                                                                                        onChange={(value) => updateCartQuantity(value, product.product_id)}
                                                                                        className="select-quantity" style={{ width: 80 }}>
                                                                                        {
                                                                                            options.map((option) => (
                                                                                                <Option value={option} key={option}>{option}</Option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                    <p>
                                                                                        <span className="small_currency">NGN</span>
                                                                                        <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                    </p>
                                                                                </div>
                                                                                <button className="trash" onClick={() => removeProductFromCart(product.product_id)}>Remove</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="small-flex">
                                                                    <Select defaultValue={product.quantity} onChange={(value) => updateCartQuantity(value, product.product_id)} className="select-quantity" style={{ width: 80, margin: '0% auto' }}>
                                                                        {options.map((option) => (
                                                                            <Option value={option} key={option}>{option}</Option>
                                                                        ))
                                                                        }
                                                                    </Select>
                                                                </div>
                                                                <div className="small-flex">
                                                                    <p>
                                                                        <span className="small_currency">NGN</span>
                                                                        <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                    </p>
                                                                </div>
                                                                <div className="small-flex">
                                                                    <button className="trash" onClick={() => removeProductFromCart(product.product_id)} > Remove </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="cart-brief">
                                                    <div className="cart-brief-data">
                                                        <div>
                                                            <ul className="first-list">
                                                                {orderTotals ?
                                                                    <>
                                                                        <li>
                                                                            <span className="first">Order size </span>
                                                                            <span className="second">{cart_info.data.reduce(reduWeight, 0).toFixed(2)} kg</span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Subtotal </span>
                                                                            <span className="second">
                                                                                <span className="small_currency"></span>
                                                                                <NumberFormat prefix="" value={cart_info.data.reduce(reduPrice, 0).toFixed(2)} className="foo"
                                                                                    displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Delivery Fee</span>
                                                                            <span className="second"><NumberFormat prefix="" value={Number(orderTotals.delivery_fee).toFixed(2)} className="foo"
                                                                                displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Discounts</span>
                                                                            <span className="second"> <NumberFormat prefix="" value={Number(orderTotals.discounts).toFixed(2)} className="foo"
                                                                                displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Charges</span>
                                                                            <span className="second"><NumberFormat prefix="" value={Number(orderTotals?.charges).toFixed(2)} className="foo"
                                                                                displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Coupon</span>
                                                                            <span className="second"><NumberFormat prefix="" value={Number(orderTotals.coupon).toFixed(2)} className="foo"
                                                                                displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Total </span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(orderTotals.total_amount).toFixed(2)} className="foo"
                                                                                    displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                        </li>
                                                                    </> :
                                                                    <>
                                                                        <li>
                                                                            <span className="first">Order size </span>
                                                                            <span className="second"><Spin indicator={antIcon} /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Subtotal </span>
                                                                            <span className="second">
                                                                                <span className="small_currency"></span>
                                                                                <Spin indicator={antIcon} />
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Delivery Fee</span>
                                                                            <span className="second"><Spin indicator={antIcon} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Discounts</span>
                                                                            <span className="second"><Spin indicator={antIcon} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Charges</span>
                                                                            <span className="second"><Spin indicator={antIcon} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Coupon</span>
                                                                            <span className="second"><Spin indicator={antIcon} /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Total </span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <Spin indicator={antIcon} />
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                                }
                                                            </ul>
                                                        </div>
                                                        {checkoutDatas &&
                                                            <p className="cart-message">Min order of <span className="currency">NGN</span> <NumberFormat prefix="" value={Number((userData && userData.is_sa) ? checkoutDatas?.app_value : checkoutDatas?.value).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /> before checkout</p>
                                                        }
                                                        {userData ?
                                                            <>
                                                                {verifyingCartData ?
                                                                    <>
                                                                        <button style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} disabled className="bg_green"> Proceed to Checkout <i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></button> :
                                                                    </> :
                                                                    <>
                                                                        {!orderTotals && !checkoutDatas ?
                                                                            <button style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} disabled className="bg_green"> Proceed to Checkout <i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></button> :
                                                                            <>
                                                                                {+orderTotals?.total_amount < (userData.is_sa ? +checkoutDatas?.app_value : +checkoutDatas?.value) ?
                                                                                    <button style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} onClick={minOrderAlert} className="bg_green"> Proceed to Checkout <i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></button> :
                                                                                    <Link style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} to="/checkout" className="bg_green"> Proceed to Checkout <i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></Link>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {!orderTotals && !checkoutDatas ?
                                                                    <button style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} disabled className="bg_green"> Proceed to Checkout <i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></button> :
                                                                    <>
                                                                        {+orderTotals?.total_amount < +checkoutDatas?.value ?
                                                                            <button style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} onClick={minOrderAlert} className="bg_green"> Proceed to Checkout <i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></button> :
                                                                            <Link style={{ width: '100%', textAlign: 'center', marginTop: '25px', clear: 'both', display: 'block' }} className="bg_green" to={`/signin/?redir=checkout`}>Proceed to Checkout<i style={{ color: '#fff' }} className="uil uil-angle-right-b"></i></Link>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    {orderTotals &&
                                                        <p className="cart-message">Min order of <span className="currency">NGN</span> <NumberFormat prefix="" value={Number(orderTotals.free_delivery_fee).toFixed(2)} className="foo"
                                                            displayType={'text'} thousandSeparator={true} /> attracts free delivery</p>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="empty-cart">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>You have no products in cart yet? <br /><Link to="/shop">Go to shop</Link></span>} />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <Recommended products={trend} param="More items to add to your cart" style={{ clear: 'both' }} />
                    </div>
                </div>
            </Spin >
            <Footer />
        </div >
    )
}

export default Cart;