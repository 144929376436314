const routes = {
    terms: "/terms",
    policies: "/privacy",
    vendor: "/vendor",
    joinus: "/joinus",
    contact: "/contact",
    faq: "faq",
    shop: "/shop",
    bills: "/bills",
    wise9ja: "/wise9ja",
    notifications: "/notifications",
    cart: "/cart",
    signin: "/signin",
    signup: "/signup",
    signout: "/signout",
    overview: "/profile/dashboard",
    profile_wishlist: "/profile/wishlist",
    profile_sl: "/profile/sl",
    profile_referral: "/profile/referral",
    profile_orders: "/profile/order_history",
    profile_loyalty: "/profile/loyalty",
    profile_topup: "/profile/topup",
    profile_topuphistory: "/profile/topuphistory",
    profile_paybill: "/profile/paybillhistory",
    profile_delivery_details: "/profile/delivery_details"
}

export default routes;